import LoaderComponent from './components/LoaderComponent/LoaderComponent.lazy';
import { Toaster } from "react-hot-toast";
import './styles.scss';
import { useEffect } from 'react';
// import { openSocket } from './service/socket.service';

const App = () => {
  useEffect(() => {
    // Open the socket connection only once
    // openSocket();

    // Cleanup: Disconnect the socket when the component unmounts
    // return () => {
    //   if (socket && socket.connected) {
    //     socket.disconnect();
    //   }
    // };
  }, []); // Empty array ensures this runs only on mount and unmount
  return (
    <>
      <LoaderComponent/>
      <Toaster position="top-right"/>
    </>
  )
}

export default App;
