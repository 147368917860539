import { Button, Modal } from 'react-bootstrap';
import { patientPaymentSourceType } from '../../types';
import { useState } from 'react';
import { closeExisingPaymentSourceModal } from '../../service/shared.service';
import './ExistingPaymentSources.scss';
interface existingPaymentSourceProps {
    patient_sources: patientPaymentSourceType[], 
    isActive: boolean, 
    onHide: (type: string, data: any) => void, 
    patient_name: string
}

export const ExistingPaymentSources = ({patient_sources, isActive, onHide, patient_name}: existingPaymentSourceProps) => {
    const [selectCard, setSelectCard] = useState<patientPaymentSourceType>();
    const handleClose = (type: string, data?: any) => onHide(type, data);

    return (
        <>
            <Modal show={isActive} onHide={() => handleClose(closeExisingPaymentSourceModal.CLOSE_MODAL)}>
                <Modal.Header closeButton className='p-4'>
                    <Modal.Title className='fs-18 fw-700'>Choose payment method</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    {patient_sources && patient_sources.length && patient_sources.map((item: patientPaymentSourceType, index: number) => (
                        <div key={index} onClick={() => setSelectCard(item)}  className={`d-flex align-items-center p-3 radius-8  justify-content-between ${item === selectCard ? 'selected-card' : ''}`}>
                            <span className='d-flex align-items-center'>
                           <img alt="" src={'/assets/images/card-type/'+ item.brand +'.svg'} className='me-3' style={{width: '80px', height: '64px'}}/>
                           <span>
                                <h4 className='fs-18 fw-700 m-0 pb-2'>{patient_name}</h4>
                                <span  className='fs-16 fw-500 sc'>****{item.last4}</span>
                            </span>
                            </span>
                            {item === selectCard && (
                            <img src="/assets/images/green-tick-circle.svg" alt="" />
                            )}
                        </div>
                    ))}
                    <div onClick={() => {handleClose(closeExisingPaymentSourceModal.ADD_CARD)}} className='fs-18 fw-700 text-primary my-4 pb-2 cursor' >+ Add new card</div>
                    <div className="d-flex p-3 radius-8 mb-4 bg-lightgrey">
                        <img src="/assets/images/shield-check.svg" alt="" className='me-3' />
                        <p className='fs-18 fw-600 m-0'>
                        We adhere entirely to the data security standards of the payment card industry.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4 justify-content-start'>
                    <Button disabled={!selectCard || (selectCard && !selectCard.last4)} variant="primary" className='btn btn-secondary py-2 fw-700' onClick={() => handleClose(closeExisingPaymentSourceModal.SELECTED_CARD, selectCard?.source_id)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

