import React, { lazy, Suspense } from 'react';

const LazyCustomerDetails = lazy(() => import('./CustomerDetails'));

const CustomerDetails = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyCustomerDetails {...props} />
  </Suspense>
);

export default CustomerDetails;
