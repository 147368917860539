import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Redux/store.tsx';
import RouterComponent from './routes';
// import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
        <App />
        <RouterComponent />
      {/* <PersistGate loading={null} persistor={persistor}>
      </PersistGate> */}
    </React.StrictMode>
  </Provider>
);

