import { Component } from "react";
// import { toast} from "react-toastify";
import toast from 'react-hot-toast';
// import "react-toastify/dist/ReactToastify.css";

class toastrSer extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
  }

  showError = (msg: any) => {
    toast.error(msg,
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
  };

  showSuccess = (msg: any) => {
    toast.success(msg,
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
  };

  // showInfo = (msg: any) => {
  //   toast.info(msg);
  // };

  // showWarn = (msg: any) => {
  //   toast.warn(msg);
  // };
}

export default toastrSer;
