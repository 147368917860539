interface HttpApiParams {
  api: string;
  body?: Record<string, any>; // Change this to a more specific type if known
  type?: apiMethodType;
  content_type?: string;
}

export type apiMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'form';

export interface HttpApiResponse<T = any> {
  data?: T;
  status?: number;
  original_text?: string;
  is_error?: number
}

const baseUrl = process.env.REACT_APP_API_URL || "https://testcustomer.recuvery.com/customer/";
let headers: any = {
  Accept: "*/*",
  "x-client-url": window.location.href,
  "x-lan-code": "en",
  app_version: 130,
  app_type: 10,
  device_token: "Website",
  device_type: 0,
  environment: process.env.REACT_APP_SERVER,
};

async function httpApiCall(params: HttpApiParams): Promise<any> {
  // params.body = { ...defaultBodyParams, ...params.body };
  let body: any = params.body || {};

  // headers = {...defaultBodyParams, ...headers}
  let url = baseUrl + params.api;
  const type = JSON.parse(JSON.stringify(params.type));
  if (params.type === 'form') {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      const value = body[key];
      console.log(value);
      if (value !== undefined && value !== null) {
        formData.append(key, value as string | Blob);
      }
    });
    body = formData;
    params.type = 'POST';
  } else {
    headers['Content-Type'] = 'application/json';
    if (params.type === 'GET' && body) {
      body = new URLSearchParams(body).toString();
      url = `${url}?${body}`;
    } else {
      body = JSON.stringify(body);
    }
  }
  const fetchOptions: any = {
    method: params.type
  };
  
  if (type !== 'form') {
    fetchOptions.headers = headers;
  }

  if (params.type === 'POST' && body) {
    fetchOptions.body = body;
  }

  // Retry logic
  const maxRetries = 1;
  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        // Handle non-2xx status codes
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      attempt++;
      if (attempt >= maxRetries) {
        console.error('API call failed after 3 attempts:', error);
        return { is_error: 1, original_text: 'Failed to fetch data after multiple attempts' };
      }
    }
  }

  return { is_error: 1, original_text: 'Unexpected error occurred' };
}

export async function downloadApi(params: HttpApiParams) {
  try {
    let url = baseUrl + params.api;
    const fetchOptions: any = {
      method: params.type,
      responseType: 'blob' as 'json', 
      observe: 'response' as 'body',
      headers
    };
  
    if (params.type === 'POST' && params.body) {
      fetchOptions.body = params.body;
    } else  if (params.type === 'GET' && params.body){
      const body = new URLSearchParams(params.body).toString();
      url = `${url}?${body}`;
    }
    let response: any = await fetch(url, fetchOptions);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export default httpApiCall;
