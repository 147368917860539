import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Row, Col, Button, Form, Dropdown, OverlayTrigger } from 'react-bootstrap';
import { setContractCode } from '../../Redux/slices/payment.slice';
import { AppDispatch, RootState } from '../../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { patient_details, patientPaymentSourceType, paymentMatrix, PaymentMatrixType, PaymentMatrixTypeEnum, PaymentMethodType, PaymentParams, paymentSourceType } from '../../types';
import './PayNowComponent.scss';
import SignatureCanvas from "react-signature-canvas";
import { useNavigate, useParams} from 'react-router-dom';
import { Wrapper } from '../ModalComponent/StripeModal';
import { ConfirmationModal } from '../ModalComponent/ConfirmationModal';
import { ExistingPaymentSources } from '../ModalComponent/ExistingPaymentSources';
import { base64ToBlob, closeExisingPaymentSourceModal, getItem, renderHtMLForTotalPayable, setItem, TooltipFn } from '../../service/shared.service';
import httpApiCall from '../../service/api.service';
import toastrSer from '../../service/toast.service';
import { setLoading } from '../../Redux/slices/loader.slice';
import { loadStripe } from '@stripe/stripe-js';
import { socket } from '../../service/socket.service';
import { EmailModal } from '../ModalComponent/emailModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_51M3weOKg5KVQU6rZWg7Lhvme6TK1t163HNYgScXxDdfTsbthLEjJGrK1eqGlkO9DL79AbJbOxbBZ1kQTZ2vq8vHt00qkG7ObgF');
const PayNowComponent: React.FC = () => {
  let sigCanvas: any = {};
  const [to_pay_today, set_to_pay_today] = useState<any>();
  const [choose_plan, set_choose_plan] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const fontFamilyList: any = ['Dancing Script, cursive', 'Playwrite AR, cursive', 'Caveat, cursive', 'Playwrite AT, cursivee', 'Playwrite HR, cursive', '"Playwrite HU", cursive'];
  const [trimmedDataURL, setTrimmedDataURL] = useState<any>(null);
  const [selectedFont, setSelectedFont] = useState(fontFamilyList[0]);
  const [renderHTML, setRenderHTML] = useState<React.ReactNode>(null);
  const [paymentData, setPaymentData] = useState({
    generateSignature: true,
  });

  const [iphoneDevice, setIphoneDevice] = useState(false);
  
  const [payment_sources, set_payment_sources] = useState<patientPaymentSourceType[]>();
  const [patient_details, set_patient_details] = useState<patient_details>();

  // for stripe modal
  const [showAddNewCardModal, setAddNewCardModal] = useState(false);

  // for confirmation modal
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showEmailModal, setEmailModal] = useState(false);
  const [showExistingCardModal, setShowExistingCardModal] = useState(false);
  const [paymentParams, setPaymentParams] = useState<PaymentParams>();
  const [paymentMethods, setPaymentMethods] = useState<patientPaymentSourceType[]>();
  const [selectedCards, setSelectedCards] = useState<paymentSourceType>({
    primary: paymentMatrix,
    backup: paymentMatrix,
    recurring: paymentMatrix
  });
  const [payment_matrix_type, setPaymentMatrix_type] = useState('');
  const { selectedPaymentPlan, doctor_country_currency_info, patients, split_request_data } = useSelector((state: RootState) => state.paymentDetails || {});
  const {financeOnBusinessState} = useSelector((state: RootState) => state.loaderReducer || {});
  const toastr = new toastrSer(true);
  const dispatch = useDispatch<AppDispatch>();  
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [today] = useState(new Date().toISOString().split("T")[0]);
  const [recurring_date, set_recurring_date] = useState<any>();
  useEffect(() => {
    if (!split_request_data || (split_request_data && !split_request_data.treatment_amount)) {
      console.log(getItem('create_plan'));
      if ((getItem('partial_amount') && Number(getItem('partial_amount'))) || (getItem('create_plan'))) {
        navigate('/debt-recuvery/plans/' + token);
      } else {
        navigate('/debt-recuvery/' + token);
      }
    }
    let newDate = new Date();
    let maxDays = 30;
    if (split_request_data && split_request_data['first_recurring_days']) {
      maxDays = split_request_data['first_recurring_days'];
    }
    newDate.setDate(newDate.getDate() + maxDays); // Set the date to one month in the future
    
    // Format the date as 'YYYY-MM-DD'
    const formattedDate = newDate.toISOString().split('T')[0];
    set_recurring_date(formattedDate);
    const userAgent = navigator.userAgent;
    // Check for iOS devices (iPhone, iPad)
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIphoneDevice(true);
    }

    if (patients?.payment_sources){
      set_payment_sources(patients?.payment_sources)
    }
    if (patients) {
      const obj: patient_details = {
        patient_name: patients.patient_first_name + ' ' + patients.patient_last_name,
        city: patients.city || '',
        state: patients.state_name || '',
        postal_code: patients.zip || '',
        line1: patients.patient_address || ''
      }
      set_patient_details(obj);
    }
    if (selectedPaymentPlan) {
      const renderData = renderHtMLForTotalPayable(selectedPaymentPlan, doctor_country_currency_info);
      setRenderHTML(renderData);
    }
    // if (financeOnBusinessState && (financeOnBusinessState.contract_patient_token === token)) {
    //   dispatch(setFinancingState({contract_patient_token: ''}));
    //   navigate('/link-expire/');
    // }
    setTimeout(() => {
      saveSuggestedSignature(false)
    }, 1000)
    if (window.location.href.includes('choosePlan')) {
      let totalAmt = Number(selectedPaymentPlan?.to_pay_today);
      if (getItem('partial_amount')) {
        totalAmt+= Number(getItem('partial_amount'));
      }
      set_choose_plan(true);
      set_to_pay_today(totalAmt);
    } else {
      set_choose_plan(false);
      set_to_pay_today(split_request_data?.treatment_amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[patients, split_request_data, selectedPaymentPlan, financeOnBusinessState])

  // const changeStep = () => {
  //   dispatch(changePreviewStep(PreviewSteps.Step1));
  // }

  const hideEmailPopup = (params: any) => {
    console.log(params);
  }

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  // updates the paymentData state
  const finalPaymentData = (data: any, type: string) => {
    if (type === 'date') {
      set_recurring_date(data.target.value)
    } else {
      setTrimmedDataURL(null);
      setPaymentData((stateData: any) => ({
        ...stateData,
        generateSignature: data.target.checked,
      }))
      if (data.target.checked) {
        setTimeout(() => {
          saveSuggestedSignature();
        }, 500)
      }
    }
    // console.log(paymentData.generateSignature);
  }

  const handleShow = (payment_matrix_type: string, payment_source_type: number) => {
    setPaymentMatrix_type(payment_matrix_type);
    setPaymentParams({
      payment_matrix_type: payment_matrix_type as PaymentMatrixType,
      payment_source_type,
      contract_patient_token: token
    })
    const banks = payment_sources?.filter((item: any) => item['payment_source_type'] === 1);
    const cards = payment_sources?.filter((item: any) => item['payment_source_type'] !== 1);
    let paymentSources: patientPaymentSourceType[] = [];
    if (payment_source_type === PaymentMethodType.CARD && cards && cards.length) {
      paymentSources = cards;
      setPaymentMethods(cards);
    } else if (payment_source_type === PaymentMethodType.BANK && banks && banks.length) {
      paymentSources = banks;
      setPaymentMethods(banks);
    }
    if (paymentSources && paymentSources.length) {
      setShowExistingCardModal(true);
    } else {
      setAddNewCardModal(true);
    }
  }

  const clearSignature = () => {
    sigCanvas.clear()
  }

  const getSignature = () => {
    if (sigCanvas) {
      setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL());
    }
  }

  const saveSuggestedSignature = (showError: boolean = true) => {
    const pElement = document.getElementById('signature-text');
    if (pElement) {
      html2canvas(pElement).then((canvas) => {
        setTrimmedDataURL(canvas.toDataURL());
      }).catch((error) => {
        console.error('Error generating canvas:', error);
      });
    } else if (showError){
      toastr.showError('Please select the signature');
      return;
    }
  };

  // updates payment sources and selected cards
  const setData = (params: patientPaymentSourceType) => {
    if (params) {
      let paymentSources: patientPaymentSourceType[] = payment_sources ? [...payment_sources] : [];
      let data = paymentSources.find((item: patientPaymentSourceType) => item.last4 === params.last4 );
      if (!data || (data && !data.last4)){
        paymentSources.push(params);
        set_payment_sources(paymentSources)
      }
    }
    if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY) {
      setSelectedCards((cards: any) => ({
        ...cards,
        primary: params
      }));
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.RECURRING) {
      setSelectedCards((cards: any) => ({
        ...cards,
        recurring: params
      }));
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.BACKUP) {
      setSelectedCards((cards: any) => ({
        ...cards,
        backup: params
      }));
    }
  }

  const hide = (params: patientPaymentSourceType) => {
    if (params) {
      setData(params);
      if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY && (!selectedCards.recurring || (selectedCards.recurring && !selectedCards.recurring['last4']))) {
        setAddNewCardModal(false);
        setTimeout(() => {
          setConfirmationModal(true);
        })
      } else {
        setAddNewCardModal(false);
      }
    } else {
      setAddNewCardModal(false);
    }
  }

  const checkRecurringPrimarySame = (resp: boolean) => {
    if (resp) {
      setSelectedCards((cards: any) => ({
        ...cards,
        recurring: selectedCards.primary
      }));
    }
    setConfirmationModal(false);
  }

  const existingModalHide = (type: string, data: any) => {
    // check if add new card is triggered from existing cards modal
    if (closeExisingPaymentSourceModal.ADD_CARD === type) {
      setShowExistingCardModal(false);
      setAddNewCardModal(true);
    }

    // check if modal is just closed from existing cards modal
    if (closeExisingPaymentSourceModal.CLOSE_MODAL === type) {
      setShowExistingCardModal(false);
    }

    // check if modal is closed with source id from existing cards modal
    if (closeExisingPaymentSourceModal.SELECTED_CARD === type) {
      let paymentData: any;
      if (payment_sources && payment_sources.length) {
        paymentData = payment_sources.find((item: patientPaymentSourceType) => item.source_id === data);
      }
      setData(paymentData);
      setShowExistingCardModal(false);
    }
  }

  const saveFinancingError = async (error_message: string, error_type: string) => {
    const tracking_data = {
      contract_id: '',
      split_req_id: split_request_data?.split_req_id || '',
      error_message: error_message || '',
      error_type: error_type || '',
      payment_intent_id: '',
      contract_token: token,
      patient_id: '',
      pc_code: ''
    };

    await httpApiCall({type: 'POST', api: 'proposal/save_financing_errors', body: tracking_data});
    dispatch(setLoading(false));
    toastr.showError(error_message);
    return true;
  }

  // create_contract here
  const validate = async () => {
    if (selectedCards && !selectedCards.primary.source_id) {
      toastr.showError('Please select primary card');
      return;
    }

    if (choose_plan && selectedCards && !selectedCards.recurring.source_id) {
      toastr.showError('Please select recurring card');
      return;
    }

    if (!isChecked) {
      toastr.showError('Please read and accept terms');
      return;
    }

    if (!trimmedDataURL && !paymentData.generateSignature) {
      toastr.showError('Signature is missing');
      return;
    }

    if (!choose_plan && (!split_request_data || (split_request_data && !split_request_data.patient_email))) {
      setEmailModal(true);
    } else {
      createContract();
    }
    
  }

  const createContract = async () => {
    dispatch(setLoading(true));
    const blob = await base64ToBlob(trimmedDataURL);
    const uploadResp = await httpApiCall({ type: 'form', api: 'proposal/upload_signature', body: { patient_signature: blob } })
    if (!uploadResp.is_error) {
      const data: any = uploadResp;
      const specificDate = new Date(recurring_date);
      const year = specificDate.getFullYear();
      const month = String(specificDate.getMonth() + 1).padStart(2, '0');
      const day = String(specificDate.getDate()).padStart(2, '0');
      const body = {
        contract_patient_token: token,
        patient_signature: data['patient_signature'],
        start_date: choose_plan ? `${year}-${month}-${day}` : undefined,
        source_id: selectedCards.primary.source_id,
        recurring_source_id: choose_plan ? selectedCards.recurring.source_id : undefined,
        alternate_recurring_source_id: choose_plan ? selectedCards.backup.source_id : undefined,
        contract_type: split_request_data && split_request_data.contract_type,
        payment_source_type: selectedCards.primary.payment_source_type
      }
      let api = 'proposal/create_contract';
      if (!choose_plan) {
        api = 'proposal/payoff_debt';
      }
      // const data = {
      const response = await httpApiCall({ type: 'POST', api, body });
      if (!response.is_error) {
        setItem('partial_amount', null);
        setItem('create_plan', null);
        let error_message = '';
        let error_type = 'PaymentError';

        const stripe = await stripePromise;
        if (stripe && response.data && response.data.client_secret) {

          stripe.confirmCardPayment(response.data.client_secret).then(result => {
            if (result.error) {
              console.error('Payment error:', result.error);
              error_message = result.error.message || '';
              error_type = result.error.code || '';
              saveFinancingError(error_message, error_type);
            } else {
              dispatch(setLoading(false));
              // toastr.showSuccess('Successfully created the contract');
              dispatch(setContractCode(response.data.contract_code));
              navigate('/debt-recuvery/plan-created/' + token);
            }
          }).catch(e => {
            console.error('Payment error in catch:', e);
            error_message = e.message || e;
            saveFinancingError(error_message, e.type);
          });

        } else {
          toastr.showError('Something went wrong, please try again later');
          return;
        }
      } else {
        dispatch(setLoading(false));
        toastr.showError(response.original_text);
      }
    } else {
      setLoading(false);
      toastr.showError('Wrong file');
    }
  }

  const changeStep = () => {
    if ((getItem('partial_amount') && Number(getItem('partial_amount'))) || (getItem('create_plan'))) {
      navigate('/debt-recuvery/plans/' + token);
    } else {
      navigate('/debt-recuvery/' + token);
    }
  }

  // const renderTooltip = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     It includes Enrollment fee and Transaction fees
  //   </Tooltip>
  // );

  return (
    <>
      <div className="payment-wrapper  d-flex flex-column justify-content-between">
        <div className="h-100 overflow-auto pt-md-5 p-3 p-md-4  container hide-scroll-bar">
          <Row className="mb-3 m-0">
            <Col className='p-0'>
              <h2 className="fs-28 fw-800 heading-text">How would you like to pay?</h2>
              <p className="fs-16 fw-400 d-none d-sm-flex">
                Before proceeding, verify all details and set payment method.
              </p>
              {/* <div className="p-3 radius-16 border text-center d-flex flex-column justify-content-between d-sm-none my-3">
                <div>
                  <label>Total outstanding balance</label>
                  <span>{split_request_data?.treatment_amount}</span>
                </div>
                <div>
                  <button>Pay via card</button>
                  <button>Pay via bank</button>
                </div>
              </div> */}
            </Col>
          </Row>

          <Row className="mb-3 m-0 align-items-center">
            <Col className="p-0 ">
              <p className="fs-16 fw-400 m-0 mb-3 d-sm-none">
              Payment Method
              </p>
              <p className="fs-16 fw-400 m-0 d-none d-sm-flex">Total outstanding balance
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={TooltipFn(renderHTML)}>
                    <img className="ms-2 cursor" src="/assets/images/info-circle.svg" style={{width: '20px'}} alt=''/>
                </OverlayTrigger>


              </p>
              <p className="fs-32 fw-700 m-0 d-none d-sm-flex">
                {doctor_country_currency_info?.currency?.[0]?.display_logo}
                {to_pay_today}
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {(!selectedCards.primary || (selectedCards.primary && selectedCards.primary.last4 === 0)) && <div className='d-flex align-items-center'>
                {iphoneDevice && <Button
                  variant="outline"
                  className="btn me-3 fw-800 d-flex align-items-center bg-black text-white"
                // onClick={() => handleShow()}
                >
                  <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                  Pay
                </Button>}
                <Button
                  variant="outline"
                  className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.CARD)}
                >
                  <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" />
                  Card
                </Button>
                <Button
                  variant="outline"
                  className="btn fw-500 d-flex align-items-center text-primary border-primary"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.BANK)}
                >
                  <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                  Bank
                </Button>
              </div>}
              {selectedCards.primary && selectedCards.primary.last4 !== 0 && (
                <div className="d-flex p-1 border radius-4 align-items-center">
                  <img alt="" src={"/assets/images/card-type/" + selectedCards.primary.brand + ".svg"}
                    className="me-2"
                    style={{ width: "30px", height: "18px" }}
                  />
                  <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                    <span className="me-2">****</span>
                    {selectedCards.primary.last4}
                  </p>
                  <img alt="" src="/assets/images/edit-pencil.svg" className="ms-2 cursor" onClick={() =>
                    handleShow(
                      PaymentMatrixTypeEnum.PRIMARY,
                      PaymentMethodType.CARD
                    )
                  }
                  />
                </div>
              )}
            </Col>
          </Row>

          <hr />
          {choose_plan && <><Row className="mb-3 m-0">
            <Col className="p-0 mb-3">
              <p className="fs-16 fw-400 m-0  d-sm-none">
                Recurring Payment Method
              </p>
              <p className="fs-14 fw-600 m-0 d-none d-sm-flex">
                Recurring Payment
              </p>
              <p className="fs-18 fw-700 m-0 d-none d-sm-flex">
                {doctor_country_currency_info?.currency?.[0]?.display_logo}
                {selectedPaymentPlan &&
                  selectedPaymentPlan.recurring_amount}{" "}
                for next {selectedPaymentPlan && selectedPaymentPlan.month}{" "}
                months
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {(!selectedCards.recurring || (selectedCards.recurring && selectedCards.recurring.last4 === 0)) && <div className='d-flex align-items-center'>
              {iphoneDevice && <Button
                  variant="outline"
                  className="btn me-3 fw-800 d-flex align-items-center bg-black text-white">
                  <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                  Pay
                </Button>}
                <Button
                  variant="outline"
                  className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.RECURRING, PaymentMethodType.CARD)}>
                  <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" />
                  Card
                </Button>
                <Button
                  variant="outline"
                  className="btn fw-500 d-flex align-items-center text-primary border-primary"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.RECURRING, PaymentMethodType.BANK)}
                >
                  <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                  Bank
                </Button>
              </div>}
              {selectedCards.recurring && selectedCards.recurring.last4 !== 0 && (
                <div className="d-flex p-1 border radius-4 align-items-center">
                  <img alt="" src={"/assets/images/card-type/" + selectedCards.recurring.brand + ".svg"}
                    className="me-2"
                    style={{ width: "30px", height: "18px" }}
                  />
                  <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                    <span className="me-2">****</span>
                    {selectedCards.recurring.last4}
                  </p>
                  <img src="/assets/images/edit-pencil.svg" className="ms-2 cursor" alt="" onClick={() =>
                    handleShow(
                      PaymentMatrixTypeEnum.RECURRING,
                      PaymentMethodType.CARD
                    )
                  }
                  />
                </div>
              )}

            </Col>
          </Row>

          <hr />

          <Row className="mb-3 m-0">
            <Col className="d-flex align-items-center p-0 mb-3">
              <p className="fs-18 fw-500 m-0">Recurring Date </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {recurring_date && <Form.Control value={recurring_date} min={today} max={recurring_date} className="w-auto" type="date" onChange={($event) => finalPaymentData($event, 'date')} />}
            </Col>
          </Row>

          <hr />


          <Row className="mb-3 m-0">
            <Col className="p-0 mb-3">
            <p className="fs-14 fw-600 m-0">Backup Payment Method (optional)</p>
              <p className="fs-12 m-0  d-none d-sm-flex">
                A backup payment method is needed in case the primary payment method fails during a payment attempt.
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {(!selectedCards.backup || (selectedCards.backup && selectedCards.backup.last4 === 0)) && <div className='d-flex align-items-center'>
                {iphoneDevice && <Button
                  variant="outline"
                  className="btn me-3 fw-800 d-flex align-items-center bg-black text-white">
                  <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                  Pay
                </Button>}
                <Button
                  variant="outline"
                  className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.BACKUP, PaymentMethodType.CARD)}>
                  <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" />
                  Card
                </Button>
                <Button
                  variant="outline"
                  className="btn fw-500 d-flex align-items-center text-primary border-primary"
                  onClick={() => handleShow(PaymentMatrixTypeEnum.BACKUP, PaymentMethodType.BANK)}
                >
                  <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                  Bank
                </Button>
              </div>}
              {selectedCards.backup && selectedCards.backup.last4 !== 0 && (
                <div className="d-flex p-1 border radius-4 align-items-center">
                  <img alt="" src={"/assets/images/card-type/" + selectedCards.backup.brand + ".svg"}
                    className="me-2"
                    style={{ width: "30px", height: "18px" }}
                  />
                  <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                    <span className="me-2">****</span>
                    {selectedCards.backup.last4}
                  </p>
                  <img src="/assets/images/edit-pencil.svg" className="ms-2 cursor" alt="" onClick={() =>
                    handleShow(
                      PaymentMatrixTypeEnum.BACKUP,
                      PaymentMethodType.CARD
                    )
                  }
                  />
                </div>
              )}

            </Col>
          </Row>

          <hr /></>}

          <Row className="mb-3 m-0">
            <Col className="d-flex align-itwms-center justify-content-between p-0">
              <p className="fs-18 fw-600 m-0">Customer Signature</p>
              <span className="d-flex align-items-center">
                <label className="switch me-2">
                  <input type="checkbox" id="toggleSwitch" checked={paymentData.generateSignature} onChange={($event) => finalPaymentData($event, 'signature')} />
                  <span className="slider"></span>
                </label>
                <label className="fs-14 fw-400 m-0">Generate Signature</label>
              </span>
            </Col>
          </Row>
          <Row className="mb-3 m-0">
            <Col className='p-0'>
              <div
                className="signature-panel radius-8 position-relative d-flex align-items-center justify-content-center"
                style={{ border: "2px solid #ced4da", height: '300px' }} >
                {paymentData.generateSignature ? (
                  <>
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center'>

                      <p className='fs-60' id="signature-text" style={{ fontFamily: selectedFont, transform: 'rotate(-15deg)' }}>{(patients?.patient_first_name || '') + (patients?.patient_last_name || '') || 'Aniket Bhupati'}</p>
                    </div>

                    <Dropdown className="position-absolute  cursor  rounded-circle hw-40" style={{ right: '25px', bottom: '25px' }}>
                      <Dropdown.Toggle className='bg-transparent border-0 rounded-circle' style={{ minWidth: 'unset' }} >
                        <img
                          src="/assets/images/fonts-icon.svg"
                          alt=""
                          id="dropdown-basic"
                          className="position-absolute bottom-0 end-0 cursor"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='decoration-none p-3 radius-8 border bg-white'>
                        {fontFamilyList && fontFamilyList.map((item: any, index: number) => (
                          <Dropdown.Item key={index} onMouseEnter={(event: any) => {
                            // console.log(fontFamilyList.indexOf(event.target['textContent']))
                            setSelectedFont(event.target['textContent']);
                            saveSuggestedSignature();
                          }
                          }
                            className={"d-flex justify-content-between cursor " + (selectedFont === item ? 'active' : '')}
                          >{item}</Dropdown.Item>
                        )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <div className='h-100 w-100'>
                    <SignatureCanvas ref={(ref) => { sigCanvas = ref; }} penColor="black" canvasProps={{ className: "sigCanvas w-100 h-100" }} onEnd={getSignature}/>
                    <img src="/assets/images/eraser-icon.svg" alt="" className="position-absolute cursor" style={{ right: "25px", bottom: "25px" }} onClick={clearSignature} />
                  </div>
                )}
              </div>

            </Col>
          </Row>

          <Row className="mb-3 m-0 p-0">
            <Col className='p-0'>
              <Form.Check
                checked={isChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
                label={
                  <>
                    I've read & agreed to the <a href='/tila'>TILA Disclosure</a>, <a href='/agreement'>Payment Authorization Agreement</a> and <a href='/hipaa'>HIPAA Disclosure</a>.
                  </>
                  }
              />
            </Col>
          </Row>
        </div>
        <div className=" d-flex justify-content-end align-items-center payment-footer  w-100 p-4 py-3 border-top">
          <img
            className="cursor"
            src="/assets/images/back-circle.svg"
            alt=""
            onClick={() => changeStep()}
          />
          <button onClick={() => validate()} className="btn btn-secondary fs-18 fw-700">
            Pay {doctor_country_currency_info?.currency?.[0]?.display_logo}
            {to_pay_today}
          </button>
        </div>
      </div>

      {showConfirmationModal && <ConfirmationModal isActive={showConfirmationModal} onHide={(params: any) => checkRecurringPrimarySame(params)} paymentParams={selectedCards.primary} />}

      {showAddNewCardModal && <Wrapper isActive={showAddNewCardModal} onHide={(params) => hide(params)} paymentParams={paymentParams} patient_details={patient_details} />}

      {showExistingCardModal && <ExistingPaymentSources isActive={showExistingCardModal} onHide={(type: string, data: any) => existingModalHide(type, data)} patient_name={patients ? patients.patient_first_name + ' ' + patients.patient_first_name : ''} patient_sources={paymentMethods || []} />}

      {showEmailModal && <EmailModal isActive={showEmailModal} onHide={(params: any) => hideEmailPopup(params)}/>}
    </>
  );
};

export default PayNowComponent;
