import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toastrSer from '../../service/toast.service';


export const EmailModal = ({ isActive, onHide}: any) => {
    const handleClose = () => onHide(email && email.length ? email : null);
    const [email, setEmailData] = useState('');
    const toastr = new toastrSer(true);
    const submitAmount = () => {
        if (!email || (email && !email.trim().length)) {
            toastr.showError('Invalid email');
            return false;
        }
        handleClose();
    }

    return (
        <>
            <Modal show={isActive} onHide={handleClose}>
                <Modal.Header closeButton className='p-4'>
                    <Modal.Title className='fs-18 fw-700'>Enter Email</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <input type='text' onChange={($event) => setEmailData($event.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-primary' onClick={submitAmount}>Continue</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};