import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'react-bootstrap';
import { PaymentParams } from '../../types/common';
import httpApiCall from '../../service/api.service';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Redux/slices/loader.slice';
import { patient_details } from '../../types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_51M3weOKg5KVQU6rZWg7Lhvme6TK1t163HNYgScXxDdfTsbthLEjJGrK1eqGlkO9DL79AbJbOxbBZ1kQTZ2vq8vHt00qkG7ObgF');

interface StripeModalProps {
   isActive?: boolean;
   onHide?: (param?: any) => void;
   paymentParams: PaymentParams | undefined;
   onPaymentConfirm?: (param?: any) => void;
   patient_details?: patient_details | undefined;
   default_details?: any
}

// Call this wrapper whenever we need to trigger stripe for bank or card
export const Wrapper: React.FC<StripeModalProps> = ({isActive, onHide, paymentParams, patient_details}) => {
   const [clientSecret, setClientSecret] = useState('');
   const [options, setOptions] = useState<any>();
   const [default_details, set_default_details] = useState<any>();
   const dispatch = useDispatch();
   useEffect(() => {
      // console.log(params);
      const options = {
         mode: 'billing',
         defaultValues: {
           name: patient_details?.patient_name || '',
           address: {
             line1: patient_details?.line1 || '',
             city: patient_details?.city || '',
             state: patient_details?.state || '',
           },
         },
      };
      set_default_details(options);

      createClientSecret(paymentParams);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [paymentParams])

   const createClientSecret = async (params: PaymentParams | undefined) => {
      const data = {
         type: 'POST' as 'POST',
         body: params,
         api: 'proposal/create_payment_intent'
      }
      dispatch(setLoading(true));
      const response: any = await httpApiCall(data);
      dispatch(setLoading(false));
      setOptions({
         // passing the client secret obtained from the server
         clientSecret: response.client_secret
      });
      setClientSecret(response.client_secret)
   }
   
   const handleClose = (param?: any) => {
      if (onHide) {
         if (param) {
            onHide(param);
         } else {
            onHide(false);
         }
      } 
   }

   return (
      <Modal show={isActive} onHide={() => handleClose(false)}>
         <Modal.Header closeButton className='p-4' >
            <Modal.Title className='fs-18 fw-700'>Stripe</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {clientSecret && clientSecret.length && (
               <Elements stripe={stripePromise} options={options}>
                  <StripeModal paymentParams={paymentParams} onHide={handleClose} default_details={default_details}/>
               </Elements>
            )}
         </Modal.Body>
      </Modal>
   )
};

// this modal opens the payment form within wrapper
const StripeModal: React.FC<StripeModalProps> = ({paymentParams, onHide, default_details}) => {
   const stripe = useStripe();
   const elements = useElements();
   const dispatch = useDispatch();
   // const [default_details, set_default_details] = useState<any>();

   // useEffect(() => {
   //    const options = {
   //       mode: 'billing',
   //       defaultValues: {
   //         name: patient_details?.patient_name || '',
   //         address: {
   //           line1: patient_details?.line1 || '',
   //           city: patient_details?.city || '',
   //           state: patient_details?.state || '',
   //         },
   //       },
   //    };
   //    set_default_details(options);
   // }, [patient_details])

   const handleSubmit = async () => {
      if (!stripe || !elements) return;
      let element: any;
      if (elements) {
         element = elements.getElement(PaymentElement);
      }

      if (!element) {
         return;
      }

      if (stripe) {
         dispatch(setLoading(true));
         stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
               return_url: 'http://localhost:3000/pay-now/fmYVEaQd2Y'
            },
            redirect: 'if_required'
         }).then(async (result: any) => {
            if (result.error) {
               dispatch(setLoading(false));
            } else {
               const param: any = paymentParams;
               param['setup_id'] = result.setupIntent && result.setupIntent['id'];
               const data = {
                  type: 'POST' as 'POST',
                  body: param,
                  api: 'proposal/add_payment_source'
               }

               const response: any = await httpApiCall(data);
               dispatch(setLoading(false));
               if (!response.is_error && onHide) {
                  onHide(response);
               }
            }
         })
      }
   }

   return (
      <>
         <PaymentElement />
         <AddressElement options={default_details} />
         <button onClick={handleSubmit} type="submit" disabled={!stripe} className='btn btn-secondary mt-3'>
            Add
         </button>
      </>
   )
};
