import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayoutLazyComponent from './components/LayoutComponent/LayoutComponent.lazy';
import InvalidComponent from './components/InvalidComponent/InvalidComponent.lazy';
import HomeComponent from './components/HomeComponent/HomeComponent.lazy';
import PlanCreated from './components/PlanCreated/PlanCreated.lazy';
import LinkExpire from './components/LinkExpire/LinkExpire.lazy';
import PayNowComponent from './components/PayNowComponent/PayNowComponent';
import PlansComponent from './components/PlansComponent/PlansComponent.lazy';
import CustomerDetails from './components/CustomerDetails/CustomerDetails.lazy';


const RouterComponent: React.FC = () => {

  // Create the router instance
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<LayoutLazyComponent />}>
          <Route path="/" element={<InvalidComponent />} />
          <Route path="/invalid" element={<InvalidComponent />} />
          <Route path="debt-recuvery">
            <Route path=":token" element={<HomeComponent />} />
            <Route path="pay-now/:token" element={<PayNowComponent />} />
            <Route path="plans/:token" element={<PlansComponent />} />
            <Route path="plan-created/:token" element={<PlanCreated />} />
            <Route path="customer-details/:token" element={<CustomerDetails />} />
          </Route>
          <Route path="/link-expire" element={<LinkExpire />} />
        </Route>
        <Route path="*" element={<InvalidComponent />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default RouterComponent;